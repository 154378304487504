@import 'config';

body {
	margin: 0;
	padding: 0;
	font-family: 'Fira Code', sans-serif;
	background-color: $color-neutral-dark;
	color: #fff;
}

* {
	box-sizing: border-box;
}

::selection {
	background-color: $color-primary-light;
	color: #fff;
}

.App {
	display: grid;
	grid-template-columns: auto 20rem;
	height: 100vh;

	&-menu {
		background-color: rgba(#fff, 0.02);
		border-left: 1px solid $color-neutral-light;
		color: #fff;
		z-index: 2;
	}

	@media screen and (max-width: $breakpoint) {
		display: flex;
		flex-direction: column;

		&-menu {
			border: none;
			border-bottom: 1px solid $color-neutral-light;
		}
	}
}
