$space: 1.5rem;
$space-xxs: $space / 6;
$space-xs: $space / 4;
$space-sm: $space / 2;
$space-lg: $space * 2;
$space-xl: $space * 4;
$space-xxl: $space * 6;

// neutral
$color-neutral-lighter: hsl(210, 17%, 40%);
$color-neutral-light: hsl(210, 16%, 24%);
$color-neutral: hsl(210, 15%, 18%);
$color-neutral-dark: hsl(210, 17%, 12%);
$color-neutral-darker: hsl(210, 18%, 9%);

// primary
$color-primary-lighter: #57abe3;
$color-primary-light: #2d96dc;
$color-primary: #0380d2;
$color-primary-dark: #036baf;
$color-primary-darker: #03568c;

$breakpoint: 850px;
