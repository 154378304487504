@import 'config';

@keyframes fadeInColor {
	from {
		background-color: #000;
	}
}

.Color {
	display: flex;
	flex-direction: column;
	flex: 1;
	text-align: center;
	color: #fff;

	.Color-swatch {
		flex: 1;
		background-color: #111;
		background-image: linear-gradient(45deg, #222 25%, transparent 25%),
			linear-gradient(-45deg, #222 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #222 75%),
			linear-gradient(-45deg, transparent 75%, #222 75%);
		background-size: 30px 31px;
		background-position: 15px 0, 15px 15px, 0 15px, 0 0;
		position: relative;

		input[type='color'] {
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
			outline: none;
			padding: 0;
			background-color: #000;
			visibility: hidden;
		}

		label {
			display: flex;
			padding-top: 50%;
			min-height: 10rem;
			transition: background-color 0.5s ease-out;
			animation: fadeInColor 0.5s ease-out;
			cursor: pointer;
			overflow: hidden;

			@media (max-width: $breakpoint) {
				min-height: 6rem;
			}

			span {
				visibility: hidden;
			}
		}
	}

	.Color-settings {
		background-color: $color-neutral-darker;
		padding: $space-xxs $space-xs;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;

		@media screen and (max-width: $breakpoint) {
			padding: 0.3rem;
			display: block;
			font-size: 0.8em;
		}

		input {
			display: inline-block;
			background-color: transparent;
			border: none;
			text-transform: uppercase;
			color: inherit;
			font: inherit;
			width: 5rem;
			font-size: 0.9em;
			line-height: $space-sm;

			&:focus {
				outline: none;
			}

			@media screen and (max-width: $breakpoint) {
				width: 100%;
				text-align: center;
			}
		}

		.Color-format {
			display: inline-block;
			font-weight: bold;
			margin-right: 0.2em;
			font-size: 1.2em;
			color: $color-neutral-lighter;
			vertical-align: middle;
			line-height: $space-sm;

			@media screen and (max-width: $breakpoint) {
				line-height: 1;
				font-size: 0.9em;
			}
		}
	}

	.Color-reset {
		background-color: transparent;
		border: none;
		cursor: pointer;
		fill: #fff;
		position: absolute;
		bottom: 3rem;
		outline: none;
		opacity: 0.5;
		background-color: rgba($color-neutral-darker, 0.8);
		border-radius: 15px;
		width: 30px;
		height: 30px;
		padding: 5px;

		svg {
			width: 20px;
			height: 20px;
		}
	}

	.Color-range {
		display: flex;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		padding: $space-xs $space;
		opacity: 0;
		transform: translateY(5px);
		transition: opacity 0.2s ease-out, transform 0.2s ease-out;

		@import 'range';
		input {
			flex: 1;
		}

		@media screen and (max-width: $breakpoint) {
			padding: $space-sm $space-xs;
		}
	}
}
