@import 'config';

.Menu {
	.Menu-item {
		font-size: 0.9rem;
		align-items: center;

		.Menu-title {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			padding: 0.5rem $space;
			letter-spacing: 0.1em;

			b {
				flex: 1;
			}

			&:hover > .Button {
				opacity: 1;
			}
		}

		.Button {
			border: none;
			background-color: transparent;
			outline: none;
			color: inherit;
			cursor: pointer;
			padding: 0;
			border-radius: 2em;
			transition: background-color 0.2s, opacity 0.2s;
			padding: 7px;
			display: flex;
			opacity: 0;

			svg {
				margin: auto;
			}

			&:hover {
				background-color: $color-primary;
			}
		}
	}
}

.Menu--level1 {
	> .Menu-item > .Menu-title {
		font-size: 0.85em;
		text-transform: uppercase;
		padding-top: $space;

		b {
			opacity: 0.4;
		}
	}
}

.Menu--level2 {
	> .Menu-item > .Menu-title {
		text-transform: capitalize;
		font-size: 1.1em;
	}

	.Menu-item {
		padding-left: 0;
	}
}
