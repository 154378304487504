@import 'config';

.Editable {
	display: inline-block;
	padding: 1em;

	&-input {
		display: inline-block;
	}

	&-button {
		display: inline-block;
		background: transparent;
		border: none;
		width: 12px;
		height: 12px;
		margin: 0 0.3em;
		vertical-align: top;
		cursor: pointer;

		&:hover path {
			fill: $color-primary-light;
		}
	}
}
