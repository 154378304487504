@import 'config';

.Output {
	&-options {
		display: flex;
		flex-direction: row;
		padding: 0.5rem $space;

		button {
			display: inline-block;
			flex: 1;
			text-align: center;
			padding: $space-xs $space-sm;
			background-color: $color-neutral-light;
			color: #fff;
			text-transform: uppercase;
			border: none;
			cursor: pointer;
			transition: background-color 0.2s;
			outline: none;
			border: 1px solid $color-neutral-darker;
			border-left: none;

			&:first-child {
				border-radius: 4px 0 0 4px;
				border-left: 1px solid $color-neutral-darker;
			}

			&:last-child {
				border-radius: 0 4px 4px 0;
			}

			&:hover,
			&:focus {
				background-color: $color-neutral;
			}

			&.is-active {
				background-color: $color-primary;
			}
		}
	}

	&-code {
		padding: $space;

		pre {
			font-family: 'Fira Code', monospace;
			margin: 0;
			line-height: 1.6;
			overflow: auto;
			font-size: 0.9em;
		}

		.comment {
			color: $color-neutral-lighter;
		}
	}
}
