@import 'config';

.ColorGroup {
	position: relative;
	box-shadow: 0 2px 10px rgba(#000, 0.2);

	.ColorGroup-title {
		text-align: center;
		font-size: 1em;
		margin: 0;
		position: absolute;
		top: $space-xs;
		left: 0;
		right: 0;
		z-index: 1;
		padding: 0.3em 0;
		opacity: 0;
		transform: translateY(-5px);
		transition: opacity 0.2s, transform 0.2s;
		pointer-events: none;

		.Editable {
			display: inline-block;
			background-color: rgba($color-neutral-darker, 0.5);
			color: #fff;
			padding: 0.2rem $space-sm 0.3rem;
			border-radius: 2em;
			pointer-events: all;
			font-size: 0.85em;
			text-transform: capitalize;
			line-height: 1;
		}
	}

	.ColorGroup-colors {
		position: relative;
		flex-direction: row;
		display: flex;
	}

	&:hover {
		.ColorGroup-title {
			opacity: 1;
			transform: translateY(0);
		}

		.Color-range {
			transform: translateY(0);
		}

		.Color-reset,
		.Color-range,
		.Color label span {
			opacity: 1;
		}

		.ColorGroup-remove {
			pointer-events: all;
			opacity: 1;
			transform: translateX(0);
		}
	}
}

.ColorGroup-footer {
	text-align: center;
	padding: $space-sm 0;
}

.ColorGroup-remove {
	position: absolute;
	right: $space-sm;
	top: $space-sm;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	background-color: rgba(#000, 0.3);
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background 0.2s, opacity 0.2s, transform 0.2s;
	cursor: pointer;
	opacity: 0;
	transform: translateX(5px);
	pointer-events: none;

	&:hover {
		background-color: rgba(#000, 0.8);
	}
}

.ColorGroup-add {
	box-sizing: content-box;
	border: none;
	background: none;
	margin: $space-sm $space;
	padding: $space-sm $space-sm;
	cursor: pointer;
	outline: none;
	border-radius: 10em;
	position: relative;
	background: $color-neutral;
	box-shadow: 0 2px 10px rgba(#000, 0.3);
	transition: background 0.2s, transform 0.2s, box-shadow 0.2s;

	svg {
		width: 20px;
		height: 20px;
		position: relative;
		z-index: 2;

		path {
			transition: fill 0.2s;
		}
	}

	&:hover,
	&:focus {
		background: $color-primary-light;
		box-shadow: 0 4px 15px rgba(#000, 0.3);
		transform: translateY(-2px);
	}
}
