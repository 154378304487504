input[type='range'] {
	--colorOne: #555;
	--colorTwo: #999;
	-webkit-appearance: none;
	margin: $space-xs 0;
	width: 100%;
}

@mixin rangeTrack {
	width: 100%;
	height: 3px;
	cursor: pointer;
	background: $color-neutral-darker;
	border-radius: 2px;
}

@mixin rangeThumb {
	box-shadow: 0 1px 3px $color-neutral-darker;
	border: 1px solid $color-neutral-darker;
	height: 18px;
	width: 18px;
	border-radius: 10px;
	background: $color-primary;
	background: linear-gradient(to left, #555, #555 50%, #999 50%, #999);
	background: linear-gradient(
		to left,
		var(--colorOne),
		var(--colorOne) 50%,
		var(--colorTwo) 50%,
		var(--colorTwo)
	);
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -8px;
}

// Track
input[type='range']::-webkit-slider-runnable-track {
	@include rangeTrack;
}

input[type='range']::-moz-range-track {
	@include rangeTrack;
}

input[type='range']::-ms-track {
	@include rangeTrack;
}

// Thumb
input[type='range']::-webkit-slider-thumb {
	@include rangeThumb;
}

input[type='range']::-moz-range-thumb {
	@include rangeThumb;
}

input[type='range']::-ms-thumb {
	@include rangeThumb;
}
